import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "../scss/styles.scss";
import Logo from "../images/logo.svg";
import WaterTech from "../images/water-technologies.svg";

const Layout = ({ children }) => {

  return (
    <>
      <Link to="/"><Logo className="logo" /></Link>
      <main>{children}</main>
      <WaterTech className="water-technologies" />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
