import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import HomeIcon from "../../images/home.svg"
import * as $ from 'jquery'

export default class PlayerControlExample extends Component {

  componentDidMount() {

    // video
    $("#video-active").on(
      "timeupdate",
      function(event){
        var time = this.currentTime;
        onTrackedVideoFrame(time);
      }
    );

    $('video').on('play',function(){
      $(this)[0].webkitEnterFullscreen();
    });

    $('video').on('ended',function(){
      $(this)[0].webkitExitFullscreen();
    });

    // track video time
    function onTrackedVideoFrame(currentTime){

      var video = document.getElementById("video-active");

      if(currentTime > 14.25 && currentTime < 14.5) {
        video.pause();
      } else if (currentTime > 17.25 && currentTime < 17.5) {
        video.pause();
      } else if (currentTime > 21.75 && currentTime < 22) {
        video.pause();
      } else if (currentTime > 28.25 && currentTime < 28.5) {
        video.pause();
      } else if (currentTime > 37.25 && currentTime < 37.5) {
        video.pause();
      } else if (currentTime > 40 && currentTime < 40.25) {
        video.pause();
      } else if (currentTime > 42.25 && currentTime < 42.5) {
        video.pause();
      } else if (currentTime > 44.75 && currentTime < 45) {
        video.pause();
      } else if (currentTime > 46.75 && currentTime < 47) {
        video.pause();
      } else if (currentTime > 49.75 && currentTime < 50) {
        video.pause();
      } else if (currentTime > 51.75 && currentTime < 52) {
        video.pause();
      } else if (currentTime > 55 && currentTime < 55.25) {
        video.pause();
      } else if (currentTime > 77.75 && currentTime < 78) {
        video.pause();
      }
    }
  }

  render() {

    return (
      <Layout>

      <Link to="/" className="gohome">
        <HomeIcon />
      </Link>

        <section className="video-page text-center">
          <div className="container">

            <h1>Base Orion Unit Plus Options With Recovery RO</h1>

            <div className="video-holder">
              <video
                id="video-active"
                className="video-active" preload="auto" controls>
                <source src="https://player.vimeo.com/external/408751054.hd.mp4?s=963cc09513689377ca769d47630f853d0abe261b&profile_id=175" type='video/mp4'/>
              </video>
            </div>

            <Link to="/" className="btn plus-before">Start again</Link>
          </div>
        </section>
      </Layout>
    );
  }
}
